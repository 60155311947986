import { CRM_API, RequestHelper } from "utils";

const RUTA = "aprobacion-comercial-recibida";
export const AprobacionComercialRecibidaService = (() => {
  const reporte = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/reporte`, params, true);
    });
  };

  return {
    reporte,
  };
})();
