const FACTURA_DE_CRÉDITO_FISCAL_ELECTRÓNICA = "31";
const FACTURA_DE_CONSUMO_ELECTRÓNICA = "32";
const NOTA_DE_DEBITO_ELECTRONICA = "33";
const NOTA_DE_CREDITO_ELECTRONICA = "34";
const COMPRAS_ELECTRÓNICO = "41";
const GASTOS_MENORES_ELECTRONICO = "43";
const REGÍMENES_ESPECIALES_ELECTRÓNICO = "44";
const GUBERNAMENTAL_ELECTRÓNICO = "45";
const COMPROBANTE_DE_EXPORTACIONES_ELECTRÓNICO = "46";
const COMPROBANTE_PARA_PAGOS_AL_EXTERIOR_ELECTRÓNICO = "47";

const PROCESO_RECEPCION_COMPROBANTES = "Recepcion E-NCF";
const PROCESO_RECEPCION_APROBACION_COMERCIAL = "Recepcion Aprobación Comercial";
const PROCESO_AUTENTICACION_SEMILLA =
  "Autenticacion Solicitud Archivo Semilla.";
const PROCESO_AUTENTICACION_DEVOLVER_TOKEN = "Autenticación Devolución Token.";
const FUENTE_FACTURA_CLIENTE_CREDITO_FISCAL_ELECTRONICA =
  "Factura de Crédito Fiscal Electrónica";
const FUENTE_FACTURA_CLIENTE_GUBERNAMENTAL_ELECTRONICA =
  "Factura Gubernamental Electrónica";
const FUENTE_FACTURA_CLIENTE_REGIMEN_ESPECIAL_ELECTRÓNICA =
  "Factura Regímenes Especiales Electrónica";
const FUENTE_FACTURA_CLIENTE_EXPORTACIONES_ELECTRÓNICA =
  "Factura Exportaciones Electrónica";
const FUENTE_COMPRAS_ELECTRONICA = "Compras Electrónico";
const FUENTE_PAGOS_AL_EXTERIOR = "Pagos al Exterior";
const FUENTE_NOTA_CREDITO_ELECTRONICA = "Nota de Crédito Electrónica";
const FUENTE_NOTA_DEBITO_ELECTRONICA = "Nota de Debito Electrónica";
const FUENTE_FACTURA_DE_CONSUMO_ELECTRONICA = "Factura de Consumo Electrónica";
const FUENTE_RESUMEN_FACTURA_DE_CONSUMO_ELECTRONICA =
  "Resumen Facturas de Consumo Electrónicas";
const FUENTE_APROBACION_COMERCIAL = "Aprobación Comercial";
const FUENTE_GASTO_MENOR = "Gastos Menores Electrónico";
const FUENTE_RECEPCION_COMPROBANTES = "Servicio Recepción de Comprobantes";
const FUENTE_AUTENTICACION_SOLICITUD_SEMILLA =
  "Autenticación solicitud Archivo Semilla";
const FUENTE_AUTENTICACION_DEVOLUCION_TOKEN =
  "Autenticación Devolución de Token";
const FUENTE_RECEPCION_APROBACION_COMERCIAL =
  "Recepción de Aprobación Comercial";
const FUENTE_ENVIO_ACUSE_RECIBO = "Envio Acuse de Recibo";
const FUENTE_ANULACION_SECUENCIA = "Anulación de Secuencia";
const FUENTE_ARCHIVO_SEMILLA_ORIGINAL = "Archivo Semilla";
const FUENTE_ARCHIVO_SEMILLA_FIRMADO = "Archivo Semilla Firmado";
const FUENTE_ARCHIVO_TOKEN = "Token";
const FUENTE_ARCHIVO_CERTIFICADO = "Archivo Certificado";
const FUENTE_ARCHIVO_POSTULACION_FIRMADO = "Archivo Postulación Firmado";

export class ComprobanteElectronico {
  static get tipoComprobanteList() {
    return [
      {
        value: FACTURA_DE_CRÉDITO_FISCAL_ELECTRÓNICA,
        label: FUENTE_FACTURA_CLIENTE_CREDITO_FISCAL_ELECTRONICA,
      },
      {
        value: FACTURA_DE_CONSUMO_ELECTRÓNICA,
        label: FUENTE_FACTURA_DE_CONSUMO_ELECTRONICA,
      },
      {
        value: NOTA_DE_DEBITO_ELECTRONICA,
        label: FUENTE_NOTA_CREDITO_ELECTRONICA,
      },
      {
        value: NOTA_DE_CREDITO_ELECTRONICA,
        label: FUENTE_NOTA_CREDITO_ELECTRONICA,
      },
      {
        value: COMPRAS_ELECTRÓNICO,
        label: FUENTE_COMPRAS_ELECTRONICA,
      },
      {
        value: GASTOS_MENORES_ELECTRONICO,
        label: FUENTE_GASTO_MENOR,
      },
      {
        value: REGÍMENES_ESPECIALES_ELECTRÓNICO,
        label: FUENTE_FACTURA_CLIENTE_REGIMEN_ESPECIAL_ELECTRÓNICA,
      },
      {
        value: GUBERNAMENTAL_ELECTRÓNICO,
        label: FUENTE_FACTURA_CLIENTE_GUBERNAMENTAL_ELECTRONICA,
      },
      {
        value: COMPROBANTE_DE_EXPORTACIONES_ELECTRÓNICO,
        label: FUENTE_FACTURA_CLIENTE_EXPORTACIONES_ELECTRÓNICA,
      },
      {
        value: COMPROBANTE_PARA_PAGOS_AL_EXTERIOR_ELECTRÓNICO,
        label: FUENTE_PAGOS_AL_EXTERIOR,
      },
    ];
  }

  static get PROPIEDADES_EXPORTAR() {
    return [
      {
        value: "IdSec",
        label: "Id",
      },
      {
        value: "NombreComercial",
        label: "Contribuyente",
      },
      {
        value: "RNCEmisor",
        label: "RNC Emisor",
      },
      {
        value: "TipoeCF",
        label: "Tipo eCF",
      },
      {
        value: "eNCF",
        label: "NCF",
      },
      {
        value: "FechaEmision",
        label: "Fecha Emisión",
      },
      {
        value: "Monto",
        label: "Monto",
      },
      {
        value: "Estatus",
        label: "Estatus",
      },
    ];
  }
}
