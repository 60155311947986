import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Select from "react-select";

import useMessage from "hooks/useMessage";
import ToolTip from "components/ToolTip/ToolTip";
import { DEFAULT_PAGINATION_VALUES, StringHelper, DateHelper } from "utils";
import { GridView, SvgComponent } from "components";
import IconButton from "components/IconButton/IconButton";
import useExceptionHandler from "hooks/useExceptionHandler";
import ListHeader from "components/PantallaList/Header/ListHeader";
import { ComprobanteElectronicoManager as manager } from "managers";
import usePaginationValuesWhere from "hooks/usePaginationValuesWhere";
import Exportar from "components/Exportar/Exportar";
import { ComprobanteElectronico } from "models/ECF/ComprobanteElectronico";

export default function ComprobantesRecibidosList({
  showLoading,
  hideLoading,
}) {
  const [where, setWhere] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION_VALUES);
  const [list, setList] = useState([]);
  const [showExportar, setShowExportar] = useState(false);

  const history = useHistory();
  const { cerrarAlertas } = useMessage();
  const { handleException } = useExceptionHandler();
  const { setPaginationValuesWhere } = usePaginationValuesWhere();

  const ESTATUS_LIST = [
    { value: "", label: "Todos" },
    { value: "N", label: "Nuevos" },
    { value: "A", label: "Aprobados" },
  ];

  useEffect(() => {
    buscar();
    return () => {
      cerrarAlertas();
      hideLoading();
    };
  }, [where]);

  function buscar() {
    const whereWithPaginationValues = {
      ...where,
      ...pagination,
    };

    manager
      .listado(whereWithPaginationValues)
      .then((res) => {
        setPaginationValuesWhere(res.Headers, pagination, setPagination);
        try {
          for (let comprobante of res.Data) {
            comprobante["FechaEmision"] = DateHelper.formatStringDate(
              comprobante["FechaEmision"]
            );
            comprobante["RNCEmisor"] = StringHelper.formatRNC(
              comprobante["RNCEmisor"]
            );
            comprobante["Monto"] = StringHelper.formatCurrency(
              comprobante["Monto"]
            );
          }
        } catch (err) {}
        setList(res.Data);
      })
      .catch((err) => handleException(err))
      .finally(() => hideLoading());
  }

  const onPageIndexChanging = (pageNumber) => {
    setPagination((prevstate) => {
      return { ...prevstate, IndiceDePagina: pageNumber - 1 };
    });
    setWhere({ ...where });
  };

  const handleClick = (params) => {
    const { Id } = params;
    history.push(`/AprobacionComercialEdit?ComprobanteRecibidoId=${Id}`);
  };

  const openExportar = () => {
    setShowExportar(true);
  };
  const openExportarDetalles = () => {
    setShowExportarDetalles(true);
  };

  return (
    <>
      <Exportar
        PROPIEDADES_EXPORTAR={ComprobanteElectronico.PROPIEDADES_EXPORTAR}
        showExportar={showExportar}
        setShowExportar={setShowExportar}
        showLoading={showLoading}
        hideLoading={hideLoading}
        where={where}
        exportarCallBack={manager.exportar}
      ></Exportar>

      <ListHeader titulo="e-NCF Recibidos" exportar={openExportar} />
      <GridView
        pagination={pagination}
        setPagination={setPagination}
        allowPaging={pagination.EsPaginable}
        pageSize={pagination.CantidadPorPagina}
        pageIndex={pagination.IndiceDePagina}
        lastPageNumber={pagination.PaginasTotal}
        dataSource={list}
        onPageIndexChanging={onPageIndexChanging}
        where={where}
        setWhere={setWhere}
        height="83vh"
      >
        <GridView.Column
          width="80px"
          showClearFilter={true}
          bodyRenderer={(row) => {
            return (
              <>
                <div className="float-right">
                  <IconButton
                    onClick={() =>
                      handleClick({
                        Id: row.Id,
                      })
                    }
                  >
                    <ToolTip message="Generar Aprobación Comercial">
                      <div>
                        <SvgComponent
                          iconName="attach-file"
                          height="25px"
                          width="25px"
                        />
                      </div>
                    </ToolTip>
                  </IconButton>
                </div>
              </>
            );
          }}
        />
        <GridView.Column
          headerText="Id"
          dataField="Id"
          sortExpression="IdSec"
          width="75px"
        />
        <GridView.Column
          headerText="Contribuyente"
          dataField="NombreComercial"
          sortExpression="NombreComercial"
        />
        <GridView.Column
          headerText="RNC"
          dataField="RNCEmisor"
          sortExpression="RNCEmisor"
        />
        <GridView.Column
          headerText="Tipo"
          dataField="Tipo"
          sortExpression="Tipo"
        />
        <GridView.Column
          headerText="E-NCF"
          dataField="NCF"
          sortExpression="NCF"
        />
        <GridView.Column
          headerText="Fecha Emisión"
          dataField="FechaEmision"
          sortExpression="FechaEmision"
          hideFilters
          isDisabled
        />
        <GridView.Column
          headerText="Monto"
          dataField="Monto"
          sortExpression="Monto"
          isDisabled
        />
        <GridView.Column
          headerText="Estatus"
          dataField="EstatusDesc"
          sortExpression="Estatus"
          width="150px"
          filterRenderer={() => {
            return (
              <Select
                className="react-select primary"
                options={ESTATUS_LIST}
                value={ESTATUS_LIST.filter(function (option) {
                  return (
                    option.value ===
                    (where.Estatus ? where.Estatus : ESTATUS_LIST[0].value)
                  );
                })}
                name="Estatus"
                onChange={(e) =>
                  setWhere({
                    ...where,
                    Estatus: e.value,
                  })
                }
              />
            );
          }}
        />
      </GridView>
    </>
  );
}
