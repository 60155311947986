import React, { useEffect, useState } from "react";
import { Card, Form } from "react-bootstrap";
import Select from "react-select";
import { FormHelper } from "utils";

import useMessage from "hooks/useMessage";
import useExceptionHandler from "hooks/useExceptionHandler";
import EditHeader from "components/PantallasEdit/Header/EditHeader";
import SectionRow from "components/PantallasEdit/SectionRow/SectionRow";
import { ComprobanteElectronico } from "models/ECF/ComprobanteElectronico";
import EditContainer from "components/PantallasEdit/EditContainer/EditContainer";
import InputContainer from "components/PantallasEdit/InputContainer/InputContainer";
import useQueryParams from "hooks/useQueryParams";
import { Compania } from "models/Compania";
import { CompaniaManager } from "managers";
import EditSection from "components/PantallasEdit/EditSection/EditSection";
import DropZone from "components/DropZone/Dropzone";
import { useTheme } from "hooks";
const ESTATUS_LIST = Compania.ESTATUS_LIST;
const AMBIENTES_LIST = Compania.AMBIENTE_LIST;

export default function CompaniaEdit({ showLoading, hideLoading }) {
  const [compania, setCompania] = useState({});
  const [tituloHeader, setTituloHeader] = useState("Crear Compañia");

  const URLParameters = useQueryParams();
  const Id = URLParameters.get("Id");
  const esNuevo = !Id;
  const { Message, cerrarAlertas, Types } = useMessage();
  const { handleException } = useExceptionHandler();
  const { theme, vendorTheme } = useTheme();

  useEffect(() => {
    if (Id) {
      showLoading();
      cargarCompania(Id);
      setTituloHeader("Editar Compañia");
    } else {
      setDefaultValues();
    }
    hideLoading();
    cerrarAlertas();
    return () => {};
  }, []);

  useEffect(() => {
    console.log(theme);
  }, [compania]);

  const setDefaultValues = () => {
    let color1 = "#F16101";
    let color2 = "#8AA8B0";
    let color3 = "#FFFFFF";

    if (theme.title == "GN ECF") {
      color1 = "#D52B1E";
      color2 = "#1097AF";
      color3 = "#FFFFFF";
    }

    setCompania({
      ...Compania,
      Estatus: Compania.DEFAULT_ESTATUS_VALUE,
      Ambiente: Compania.DEFAULT_AMBIENTE_VALUE,
      Nombre: "",
      RazonSocial: "",
      Codigo: "",
      RNC: "",
      DireccionLinea1: "",
      TelefonoPrincipal: "",
      TelefonoSecundario: "",
      Email: "",
      Color1: color1,
      Color2: color2,
      Color3: color3,
      PaginaWeb: "",
      Extension1: "",
      Extension2: "",
      Fax: "",
    });
  };

  const setCheckedProp = (e) => {
    const { name, checked } = e.target;
    setCompania({
      ...compania,
      [name]: checked,
    });
  };

  const cargarCompania = (Id) => {
    CompaniaManager.buscarPorId(Id).then((data) => {
      data.Color1 = "#" + data.Color1;
      data.Color2 = "#" + data.Color2;
      data.Color3 = "#" + data.Color3;
      setCompania({ ...data, Id });
    });
  };
  const onFileChange = (propFile, propName) => {
    setCompania({ ...compania, [propName]: propFile });
  };

  function grabar() {
    showLoading();
    if (esNuevo) {
      CompaniaManager.crear(compania)
        .then((_res) => {
          Message({ message: `Grabado Exitosamente` });
          setSaved(true);
        })
        .catch((e) => handleException(e))
        .finally(() => hideLoading());
    } else {
      setCompania({ ...compania, Id: Id });
      CompaniaManager.editar(compania)
        .then(() => {
          Message({ message: `Actualizado Exitosamente` });
          cargarCompania(res.Id);
        })
        .catch((e) => handleException(e))
        .finally(() => hideLoading());
    }
  }

  return (
    <>
      <Form action="" className="form-horizontal" method="">
        <Card>
          <EditHeader
            titulo={`${tituloHeader}`}
            volver={true}
            grabar={grabar}
          />
          <EditContainer>
            <EditSection title="Información General">
              <SectionRow>
                <InputContainer label="Id">
                  <Form.Control
                    type="number"
                    name="Id"
                    value={compania.Id}
                    onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  />
                </InputContainer>
              </SectionRow>

              <SectionRow>
                <InputContainer label="Nombre">
                  <Form.Control
                    type="text"
                    name="Nombre"
                    value={compania.Nombre}
                    onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  />
                </InputContainer>
              </SectionRow>
              <SectionRow>
                <InputContainer label="Razón Social">
                  <Form.Control
                    type="text"
                    name="RazonSocial"
                    value={compania.RazonSocial}
                    onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  />
                </InputContainer>
              </SectionRow>
              <SectionRow>
                <InputContainer label="Código">
                  <Form.Control
                    type="text"
                    name="Codigo"
                    value={compania.Codigo}
                    onChange={(e) =>
                      FormHelper.handleChangeNoEspace(e, setCompania)
                    }
                  />
                </InputContainer>
              </SectionRow>
              <SectionRow>
                <InputContainer label="RNC">
                  <Form.Control
                    type="text"
                    name="RNC"
                    value={compania.RNC}
                    onChange={(e) =>
                      FormHelper.handleChangeSoloNumero(e, setCompania)
                    }
                  />
                </InputContainer>
              </SectionRow>
              <SectionRow>
                <InputContainer label="Dirección">
                  <Form.Control
                    type="text"
                    name="DireccionLinea1"
                    value={compania.DireccionLinea1}
                    onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  />
                </InputContainer>
              </SectionRow>
              <SectionRow>
                <InputContainer label="Teléfono Principal">
                  <Form.Control
                    type="text"
                    name="TelefonoPrincipal"
                    value={compania.TelefonoPrincipal}
                    onChange={(e) =>
                      FormHelper.handleChangeSoloNumero(e, setCompania)
                    }
                  />
                </InputContainer>
                <InputContainer label="Extensión 1">
                  <Form.Control
                    type="text"
                    name="Extension1"
                    value={compania.Extension1}
                    onChange={(e) =>
                      FormHelper.handleChangeSoloNumero(e, setCompania)
                    }
                  />
                </InputContainer>
              </SectionRow>
              <SectionRow>
                <InputContainer label="Teléfono Secundario">
                  <Form.Control
                    type="text"
                    name="TelefonoSecundario"
                    value={compania.TelefonoSecundario}
                    onChange={(e) =>
                      FormHelper.handleChangeSoloNumero(e, setCompania)
                    }
                  />
                </InputContainer>

                <InputContainer label="Extensión 2">
                  <Form.Control
                    type="text"
                    name="Extension2"
                    value={compania.Extension2}
                    onChange={(e) =>
                      FormHelper.handleChangeSoloNumero(e, setCompania)
                    }
                  />
                </InputContainer>
              </SectionRow>
              <SectionRow>
                <InputContainer label="Correo">
                  <Form.Control
                    type="text"
                    name="Email"
                    value={compania.Email}
                    onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  />
                </InputContainer>
                <InputContainer label="Fax">
                  <Form.Control
                    type="text"
                    name="Fax"
                    value={compania.Fax}
                    onChange={(e) =>
                      FormHelper.handleChangeSoloNumero(e, setCompania)
                    }
                  />
                </InputContainer>
              </SectionRow>
              <SectionRow>
                <InputContainer label="Página Web">
                  <Form.Control
                    type="text"
                    name="PaginaWeb"
                    value={compania.PaginaWeb}
                    onChange={(e) => FormHelper.handleChange(e, setCompania)}
                  />
                </InputContainer>
              </SectionRow>
            </EditSection>
            <EditSection title="Apariencia">
              <EditSection title="Color" mt={"mt-5"}>
                <SectionRow triple={true}>
                  <InputContainer label="Primario" labelSize={3}>
                    <Form.Control
                      name="Color1"
                      type="color"
                      onChange={(e) => FormHelper.handleChange(e, setCompania)}
                      value={compania.Color1}
                    />
                  </InputContainer>
                  <InputContainer
                    label="Secundario"
                    labelSize={3}
                    labelClassName="pl-2 d-flex"
                  >
                    <Form.Control
                      name="Color2"
                      type="color"
                      onChange={(e) => FormHelper.handleChange(e, setCompania)}
                      value={compania.Color2}
                    />
                  </InputContainer>
                  <InputContainer label="Logo">
                    <Form.Control
                      name="Color3"
                      type="color"
                      onChange={(e) => FormHelper.handleChange(e, setCompania)}
                      value={compania.Color3}
                    />
                  </InputContainer>
                </SectionRow>
              </EditSection>
            </EditSection>
            <EditSection title="Configuración">
              <div className="d-flex">
                <Form.Check
                  className="divContainer ml-2 mt-2"
                  type="checkbox"
                  aria-label="Manejar Secuencias Fiscales Automaticamente"
                  onChange={setCheckedProp}
                  id="chkManejarSecuenciasFiscalesAutomaticamente"
                  name="ManejarSecuenciasFiscalesAutomaticamente"
                  checked={compania.ManejarSecuenciasFiscalesAutomaticamente}
                />
                <label
                  htmlFor="chkManejarSecuenciasFiscalesAutomaticamente"
                  style={{ width: "450px" }}
                  className="mt-2"
                >
                  Manejar Secuencias Fiscales Automaticamente
                </label>
              </div>
            </EditSection>
          </EditContainer>
        </Card>
      </Form>
    </>
  );
}
