import { AprobacionComercialRecibidaService as service } from "services";
import { ResponseExceptionHandlerHelper } from "utils";

export const AprobacionComercialRecibidaManager = (() => {
  const { handleException } = ResponseExceptionHandlerHelper();

  const reporte = (where) => {
    return service.reporte(where).then((res) => {
      if (!res.Success) {
        handleException(res);
      }
      return res;
    });
  };

  return {
    reporte,
  };
})();
