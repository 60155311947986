export const CRM_API = process.env.REACT_APP_CRM_API_URL;
export const URL_DOCUMENTACION = process.env.REACT_APP_URL_DOCUMENTACION;

export const ERROR_DEFAULT_MESSAGE =
  "Error en la aplicación favor comunicarse con el administrador";
export const MENSAJE_ERROR_PERMISO = "Usuario no tiene este permiso";
export const BAD_GATEWAY_ERROR_MESSAGE =
  "No se pudo establecer la conexión con el Servidor";
export const NO_INTERNET_CONNECTION_MESSAGE =
  "Favor revise su conexión a Internet";

export const VALIDATION_ERROR_HTTP_CODE = 400;
export const UNAUTHORIZED_ERROR_HTTP_CODE = 401;
export const BAD_GATEWAY_ERROR_HTTP_CODE = 502;

export const HEADER_WHERE_INDICE_PAGINA = "x-where-pageindex";
export const HEADER_WHERE_CANTIDAD_PAGINA = "x-where-pagesize";
export const HEADER_WHERE_RECORDS_TOTAL = "x-where-recordstotal";
export const HEADER_SESSION_TOKEN = "x-session-token";
export const HEADER_COMPANIA_ID = "x-company";

export const SESSION_KEY = "eCFSessionId";
export const REFRESH_USER = "RefreshUserLogin";
export const COMPANIA_ID_KEY = "CompaniaId";

export const ESTADO_ECF_ACEPTADO = "A";
export const ESTADO_ECF_ACEPTADO_LABEL = "Aceptado";
export const ESTADO_ECF_ACEPTADO_CONDICIONAL = "C";
export const ESTADO_ECF_ACEPTADO_CONDICIONAL_LABEL = "Aceptado Condicional";

export const FUENTE_CONTACTO = "C";
export const FUENTE_PROSPECTO = "P";
export const FUENTE_CUENTA = "X";
export const FUENTE_OPORTUNIDAD = "O";
export const FUENTE_TAREA = "T";
export const FUENTE_CASO = "A";
export const FUENTE_USUARIO = "U";
export const FUENTE_EQUIPO = "E";
export const FUENTE_TODOS = "TDS";
export const FUENTE_COTIZACION = "Z";
export const FUENTE_NINGUNA = "Ninguna";
export const CHAT_FUENTE_GLOBAL = "G";

export const TRANSACCION_ESTATUS_NUEVA = "N";
export const TRANSACCION_ESTATUS_EN_PROCESO = "P";
export const TRANSACCION_ESTATUS_ERROR_INTERNO = "I";
export const TRANSACCION_ESTATUS_TERMINADA = "T";

export const TRANSACCION_ESTATUS_NUEVA_LABEL = "Pendiente Envio";
export const TRANSACCION_ESTATUS_EN_PROCESO_LABEL = "En Proceso";
export const TRANSACCION_ESTATUS_ERROR_INTERNO_LABEL = "Error Interno";
export const TRANSACCION_ESTATUS_TERMINADA_LABEL = "Terminada";

export const GRAFICO_PERIODICIDAD_DIARIO = "D";
export const GRAFICO_PERIODICIDAD_MENSUAL = "M";
export const GRAFICO_PERIODICIDAD_ANUAL = "A";

export const GRAFICO_PERIODICIDAD_DIARIO_LABEL = "Diario";
export const GRAFICO_PERIODICIDAD_MENSUAL_LABEL = "Mensual";
export const GRAFICO_PERIODICIDAD_ANUAL_LABEL = "Anual";

export const PERIOCIDAD_LIST = [
  {
    value: GRAFICO_PERIODICIDAD_DIARIO,
    label: GRAFICO_PERIODICIDAD_DIARIO_LABEL,
  },
  {
    value: GRAFICO_PERIODICIDAD_MENSUAL,
    label: GRAFICO_PERIODICIDAD_MENSUAL_LABEL,
  },
  {
    value: GRAFICO_PERIODICIDAD_ANUAL,
    label: GRAFICO_PERIODICIDAD_ANUAL_LABEL,
  },
];

export const TIPO_SERIE_FISCAL_FACTURA_CREDITO_FISCAL = "31";
export const TIPO_SERIE_FISCAL_FACTURA_CONSUMO = "32";
export const TIPO_SERIE_FISCAL_NOTA_DEBITO = "33";
export const TIPO_SERIE_FISCAL_NOTA_CREDITO = "34";
export const TIPO_SERIE_FISCAL_COMPRAS = "41";
export const TIPO_SERIE_FISCAL_GASTO_MENOR = "43";
export const TIPO_SERIE_FISCAL_REGIMEN_ESPECIAL = "44";
export const TIPO_SERIE_FISCAL_GUBERNAMENTAL = "45";
export const TIPO_SERIE_FISCAL_EXPORTACIONES = "46";
export const TIPO_SERIE_FISCAL_PAGOS_EXTERIOR = "47";

export const TIPO_SERIE_FISCAL_FACTURA_CREDITO_FISCAL_LABEL =
  "Factura de Crédito Fiscal Electrónica";
export const TIPO_SERIE_FISCAL_FACTURA_CONSUMO_LABEL =
  "Factura de Consumo Electrónica";
export const TIPO_SERIE_FISCAL_NOTA_DEBITO_LABEL = "Nota de Débito Electrónica";
export const TIPO_SERIE_FISCAL_NOTA_CREDITO_LABEL =
  "Nota de Crédito Electrónica";
export const TIPO_SERIE_FISCAL_COMPRAS_LABEL = "Compras Electrónico";
export const TIPO_SERIE_FISCAL_GASTO_MENOR_LABEL = "Gastos Menores Electrónico";
export const TIPO_SERIE_FISCAL_REGIMEN_ESPECIAL_LABEL =
  "Regímenes Especiales Electrónico";
export const TIPO_SERIE_FISCAL_GUBERNAMENTAL_LABEL =
  "Gubernamental Electrónico";
export const TIPO_SERIE_FISCAL_EXPORTACIONES_LABEL =
  "Comprobante de Exportaciones Electrónico ";
export const TIPO_SERIE_FISCAL_PAGOS_EXTERIOR_LABEL =
  "Comprobante para Pagos al Exterior Electrónico";

export const TIPOS_SERIES_FISCAL = [
  {
    value: TIPO_SERIE_FISCAL_FACTURA_CREDITO_FISCAL,
    label: TIPO_SERIE_FISCAL_FACTURA_CREDITO_FISCAL_LABEL,
  },
  {
    value: TIPO_SERIE_FISCAL_FACTURA_CONSUMO,
    label: TIPO_SERIE_FISCAL_FACTURA_CONSUMO_LABEL,
  },
  {
    value: TIPO_SERIE_FISCAL_NOTA_DEBITO,
    label: TIPO_SERIE_FISCAL_NOTA_DEBITO_LABEL,
  },
  {
    value: TIPO_SERIE_FISCAL_NOTA_CREDITO,
    label: TIPO_SERIE_FISCAL_NOTA_CREDITO_LABEL,
  },
  {
    value: TIPO_SERIE_FISCAL_COMPRAS,
    label: TIPO_SERIE_FISCAL_COMPRAS_LABEL,
  },
  {
    value: TIPO_SERIE_FISCAL_GASTO_MENOR,
    label: TIPO_SERIE_FISCAL_GASTO_MENOR_LABEL,
  },
  {
    value: TIPO_SERIE_FISCAL_REGIMEN_ESPECIAL,
    label: TIPO_SERIE_FISCAL_REGIMEN_ESPECIAL_LABEL,
  },
  {
    value: TIPO_SERIE_FISCAL_GUBERNAMENTAL,
    label: TIPO_SERIE_FISCAL_GUBERNAMENTAL_LABEL,
  },
  {
    value: TIPO_SERIE_FISCAL_EXPORTACIONES,
    label: TIPO_SERIE_FISCAL_EXPORTACIONES_LABEL,
  },
  {
    value: TIPO_SERIE_FISCAL_PAGOS_EXTERIOR,
    label: TIPO_SERIE_FISCAL_PAGOS_EXTERIOR_LABEL,
  },
];

export const TIPO_SERIE_FISCAL_LIST = [
  {
    value: "",
    label: "Seleccione el tipo de Comprobante Electrónico",
  },
  ...TIPOS_SERIES_FISCAL,
];

export const TIPO_SERIE_FISCAL_FILTRO = [
  {
    value: "-1",
    label: "TODAS",
  },
  ...TIPOS_SERIES_FISCAL,
];

export const FUENTE_CONTACTO_LABEL = "Contacto";
export const FUENTE_PROSPECTO_LABEL = "Prospecto";
export const FUENTE_CUENTA_LABEL = "Cuenta";
export const FUENTE_OPORTUNIDAD_LABEL = "Oportunidad";
export const FUENTE_TAREA_LABEL = "Tarea";
export const FUENTE_CASO_LABEL = "Caso";
export const FUENTE_USUARIO_LABEL = "Usuario";
export const FUENTE_EQUIPO_LABEL = "Equipo";
export const FUENTE_TODOS_LABEL = "Todos";
export const FUENTE_COTIZACION_LABEL = "Cotización";
export const CHAT_FUENTE_GLOBAL_LABEL = "Global";

export const TIPO_COMPROBANTE_FACTURA_CREDITO_FISCAL = "31";
export const TIPO_COMPROBANTE_FACTURA_CONSUMO = "32";
export const TIPO_COMPROBANTE_NOTA_DEBITO = "33";
export const TIPO_COMPROBANTE_NOTA_CREDITO = "34";
export const TIPO_COMPROBANTE_COMPRAS = "41";
export const TIPO_COMPROBANTE_GASTO_MENOR = "43";
export const TIPO_COMPROBANTE_REGIMEN_ESPECIAL = "44";
export const TIPO_COMPROBANTE_GUBERNAMENTAL = "45";
export const TIPO_COMPROBANTE_EXPORTACIONES = "46";
export const TIPO_COMPROBANTE_PAGOS_EXTERIOR = "47";

export const PERIODO_TABLA_ACTUAL = "Actual";
export const PERIODO_TABLA_PASADO = "Pasado";
export const PERIODO_TABLA_OTRO = "Otro";

export const PERIODICIDAD_TABLA_ACTUAL_HOY = "Hoy";
export const PERIODICIDAD_TABLA_ACTUAL_SEMANA = "Esta Semana";
export const PERIODICIDAD_TABLA_ACTUAL_MES = "Este Mes";
export const PERIODICIDAD_TABLA_ACTUAL_SEMESTRE = "Este Semestre";
export const PERIODICIDAD_TABLA_ACTUAL_ANIO = "Este Año";
export const PERIODICIDAD_TABLA_PASADO_AYER = "Ayer";
export const PERIODICIDAD_TABLA_PASADO_SEMANA = "Semana Pasada";
export const PERIODICIDAD_TABLA_PASADO_MES = "Mes Pasado";
export const PERIODICIDAD_TABLA_PASADO_SEMESTRE = "Semestre Pasado";
export const PERIODICIDAD_TABLA_PASADO_ANIO = "Año Pasado";

export const PERIODICIDAD_TABLA_OTRO_TODOS = "Todos";
export const PERIODICIDAD_TABLA_OTRO_RANGO = "Rango";

export const PERIODO_TABLA_LIST = [
  {
    value: PERIODO_TABLA_ACTUAL,
    label: PERIODO_TABLA_ACTUAL,
  },
  {
    value: PERIODO_TABLA_PASADO,
    label: PERIODO_TABLA_PASADO,
  },
  {
    value: PERIODO_TABLA_OTRO,
    label: PERIODO_TABLA_OTRO,
  },
];

export const TIPO_NOTAS_CANCELACION = [
  {
    value: "-1",
    label: "Seleccione Tipo de Nota",
  },
  {
    value: TIPO_COMPROBANTE_NOTA_DEBITO,
    label: TIPO_SERIE_FISCAL_NOTA_DEBITO_LABEL,
  },
  {
    value: TIPO_COMPROBANTE_NOTA_CREDITO,
    label: TIPO_SERIE_FISCAL_NOTA_CREDITO_LABEL,
  },
];

export const PERIODICIDAD_TABLA_ACTUAL_LIST = [
  {
    value: PERIODICIDAD_TABLA_ACTUAL_HOY,
    label: PERIODICIDAD_TABLA_ACTUAL_HOY,
  },
  {
    value: PERIODICIDAD_TABLA_ACTUAL_SEMANA,
    label: PERIODICIDAD_TABLA_ACTUAL_SEMANA,
  },
  {
    value: PERIODICIDAD_TABLA_ACTUAL_MES,
    label: PERIODICIDAD_TABLA_ACTUAL_MES,
  },
  {
    value: PERIODICIDAD_TABLA_ACTUAL_SEMESTRE,
    label: PERIODICIDAD_TABLA_ACTUAL_SEMESTRE,
  },
  {
    value: PERIODICIDAD_TABLA_ACTUAL_ANIO,
    label: PERIODICIDAD_TABLA_ACTUAL_ANIO,
  },
];

export const PERIODICIDAD_TABLA_PASADO_LIST = [
  {
    value: PERIODICIDAD_TABLA_PASADO_AYER,
    label: PERIODICIDAD_TABLA_PASADO_AYER,
  },
  {
    value: PERIODICIDAD_TABLA_PASADO_SEMANA,
    label: PERIODICIDAD_TABLA_PASADO_SEMANA,
  },
  {
    value: PERIODICIDAD_TABLA_PASADO_MES,
    label: PERIODICIDAD_TABLA_PASADO_MES,
  },
  {
    value: PERIODICIDAD_TABLA_PASADO_SEMESTRE,
    label: PERIODICIDAD_TABLA_PASADO_SEMESTRE,
  },
  {
    value: PERIODICIDAD_TABLA_PASADO_ANIO,
    label: PERIODICIDAD_TABLA_PASADO_ANIO,
  },
];

export const PERIODICIDAD_TABLA_OTRO_LIST = [
  {
    value: PERIODICIDAD_TABLA_OTRO_TODOS,
    label: PERIODICIDAD_TABLA_OTRO_TODOS,
  },
  {
    value: PERIODICIDAD_TABLA_OTRO_RANGO,
    label: PERIODICIDAD_TABLA_OTRO_RANGO,
  },
];

export const TIPO_COMPROBANTE_FACTURA_CREDITO_FISCAL_LABEL =
  "Factura de Crédito Fiscal Electrónica";
export const TIPO_COMPROBANTE_FACTURA_CONSUMO_LABEL =
  "Factura de Consumo Electrónica";
export const TIPO_COMPROBANTE_NOTA_DEBITO_LABEL = "Nota de Débito Electrónica";
export const TIPO_COMPROBANTE_NOTA_CREDITO_LABEL =
  "Nota de Crédito Electrónica";
export const TIPO_COMPROBANTE_COMPRAS_LABEL = "Compras Electrónico";
export const TIPO_COMPROBANTE_GASTO_MENOR_LABEL = "Gastos Menores Electrónico";
export const TIPO_COMPROBANTE_REGIMEN_ESPECIAL_LABEL =
  "Factura Regímenes Especiales Electrónica";
export const TIPO_COMPROBANTE_GUBERNAMENTAL_LABEL =
  "Factura Gubernamental Electrónica";
export const TIPO_COMPROBANTE_EXPORTACIONES_LABEL =
  "Factura Exportaciones Electrónica";
export const TIPO_COMPROBANTE_PAGOS_EXTERIOR_LABEL = "Pagos al Exterior";

export const REPORTE_CANTIDAD_TRANSACCIONES_POR_RANGO_FECHA = "C";
export const REPORTE_TRANSACCIONES_POR_RANGO_FECHA = "TF";

export const TIPO_REPORTE_COMPROBANTES_RECIBIDOS = "CR";
export const TIPO_REPORTE_APROBACIONES_RECIBIDAS = "AR";

export const TIPO_REPORTE_COMPROBANTES_RECIBIDOS_LABEL =
  "Comprobantes Recibidos";
export const TIPO_REPORTE_APROBACIONES_RECIBIDAS_LABEL =
  "Aprobaciones Comerciales Recibidas";

export const TIPO_COMPROBANTE_LIST = [
  {
    value: TIPO_COMPROBANTE_FACTURA_CREDITO_FISCAL,
    label: TIPO_COMPROBANTE_FACTURA_CREDITO_FISCAL_LABEL,
  },
  {
    value: TIPO_COMPROBANTE_FACTURA_CONSUMO,
    label: TIPO_COMPROBANTE_FACTURA_CONSUMO_LABEL,
  },
  {
    value: TIPO_COMPROBANTE_NOTA_DEBITO,
    label: TIPO_COMPROBANTE_NOTA_DEBITO_LABEL,
  },
  {
    value: TIPO_COMPROBANTE_NOTA_CREDITO,
    label: TIPO_COMPROBANTE_NOTA_CREDITO_LABEL,
  },
  {
    value: TIPO_COMPROBANTE_COMPRAS,
    label: TIPO_COMPROBANTE_COMPRAS_LABEL,
  },
  {
    value: TIPO_COMPROBANTE_GASTO_MENOR,
    label: TIPO_COMPROBANTE_GASTO_MENOR_LABEL,
  },
  {
    value: TIPO_COMPROBANTE_REGIMEN_ESPECIAL,
    label: TIPO_COMPROBANTE_REGIMEN_ESPECIAL_LABEL,
  },
  {
    value: TIPO_COMPROBANTE_GUBERNAMENTAL,
    label: TIPO_COMPROBANTE_GUBERNAMENTAL_LABEL,
  },
  {
    value: TIPO_COMPROBANTE_EXPORTACIONES,
    label: TIPO_COMPROBANTE_EXPORTACIONES_LABEL,
  },
  {
    value: TIPO_COMPROBANTE_PAGOS_EXTERIOR,
    label: TIPO_COMPROBANTE_PAGOS_EXTERIOR_LABEL,
  },
];

export const HotRefreshTimes = {
  kanban: process.env.REACT_APP_HOT_REFRESH_KANBAN || 30000,
  agenda: process.env.REACT_APP_HOT_REFRESH_AGENDA || 1000,
  chat: process.env.REACT_APP_HOT_REFRESH_CHAT || 2000,
  schedule: process.env.REACT_APP_HOT_REFRESH_SCHEDULE || 30000,
};

export const obtenerFuenteLabel = (fuente) => {
  switch (fuente) {
    case FUENTE_CONTACTO:
      return FUENTE_CONTACTO_LABEL;
    case FUENTE_PROSPECTO:
      return FUENTE_PROSPECTO_LABEL;
    case FUENTE_CUENTA:
      return FUENTE_CUENTA_LABEL;
    case FUENTE_OPORTUNIDAD:
      return FUENTE_OPORTUNIDAD_LABEL;
    case FUENTE_TAREA:
      return FUENTE_TAREA_LABEL;
    case FUENTE_CASO:
      return FUENTE_CASO_LABEL;
    case FUENTE_USUARIO:
      return FUENTE_USUARIO_LABEL;
    case FUENTE_EQUIPO:
      return FUENTE_EQUIPO_LABEL;
    case FUENTE_TODOS:
      return FUENTE_TODOS_LABEL;
    case FUENTE_COTIZACION:
      return FUENTE_COTIZACION_LABEL;
    case CHAT_FUENTE_GLOBAL:
      return CHAT_FUENTE_GLOBAL_LABEL;
  }
};

export const TIPO_COMPONENTE = {
  ATTACHMENTS: "Attachments",
  EVENTOS_FUTUROS: "EventosFuturos",
  TAREAS_FUTURAS: "TareasFuturas",
  CUENTA: "Cuenta",
  EQUIPOS: "Equipos",
  PERFILES: "Perfiles",
  CASOS: "Casos",
  LLAMADAS: "Lllamadas",
  OPORTUNIDAD: "Oportunidad",
  PROSPECTOS: "PROSPECTOS",
  OPORTUNIDADES: "Oportunidades",
  CONTACTOS: "Contactos",
  COTIZACIONES: "Cotizaciones",
};

export const TIPO_DOCUMENTO = {
  CEDULA: { value: "C", label: "Cedula" },
  RNC: { value: "R", label: "RNC" },
  PASAPORTE: { value: "P", label: "Pasaporte" },
};

export const DEFAULT_PAGINATION_VALUES = {
  EsPaginable: true,
  CantidadPorPagina: 15,
  EsOrdernable: true,
  OrderBy: "Id",
};

export const TAMANIOS_REPRESENTION_IMPRESA_LIST = [
  {
    value: "8.5x11",
    label: "8.5x11",
  },
  {
    value: "Recibo",
    label: "Recibo",
  },
];
