import { CRM_API, RequestHelper } from "utils";

const RUTA = "comprobante-electronico";
export const ComprobanteElectronicoService = (() => {
  const listado = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/listado`, params);
    });
  };

  const buscarPorId = function (args, esLookup) {
    return new Promise((res) => res(args)).then((Id) => {
      return RequestHelper(CRM_API).get(`${RUTA}/${Id}`, { esLookup });
    });
  };
  const reporte = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).get(`${RUTA}/reporte`, params, true);
    });
  };

  const exportar = function (args) {
    return new Promise((res) => res(args)).then((params) => {
      return RequestHelper(CRM_API).post(`${RUTA}/excel`, params, true);
    });
  };

  return {
    listado,
    buscarPorId,
    reporte,
    exportar,
  };
})();
